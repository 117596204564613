import { forwardRef } from 'react'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import styles from './stlyes'

interface IProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  if (!props.isOpen) return null

  return (
    <div style={styles.overlay}>
      <div style={styles.modal} ref={ref} onClick={(e) => e.stopPropagation()}>
        <Button type="primary" style={styles.closeButton} onClick={props.onClose}>
          <CloseOutlined style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }} />
        </Button>
        {props.children}
      </div>
    </div>
  )
})

export default Modal
