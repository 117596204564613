const styles = {
  overlay: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 200,
  },
  modal: {
    backgroundColor: '#fff',
    // padding: '20px',
    // borderRadius: '8px',
    position: 'relative' as 'relative',
    // width: '300px',
    maxWidth: '100%',
  },
  closeButton: {
    backgroundColor: 'white',
    padding: 0,
    position: 'absolute' as 'absolute',
    borderRadius: 50,
    width: 30,
    height: 30,
    top: -10,
    right: -10,
    zIndex: 10,
  },
}

export default styles
