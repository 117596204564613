export enum Platforms {
  auth = 'auth',
  accounts = 'accounts',
  business = 'business',
}

export enum Origins {
  store = 'store',
}

export enum LocalStorageEntities {
  products = 'products',
  account = 'account',
}

export enum AuthRoutes {
  LOGGED = 'logged/:id',
  LOGOUT = 'logout/:id',
}

export enum PublicRoutes {
  HOME = 'home',
  STORE = 'store',
  PRODUCTS = 'products',
  CART = 'cart',
  CHECKOUT = 'checkout',
  FINISH = 'finish',
  PROVIDERS = 'providers',
  PROVIDERS_DETAIL = 'providers/:brandSlug',
  PRODUCTS_DETAIL = 'products/:productId',
  PRODUCTS_DETAIL_SKU = 'products/:sku',
  REQUIREMENTS_GENERATE = 'requirements/generate/:requirementId',
  REQUIREMENTS_GENERATE_ACCOUNT_ID = 'requirements/generate/accountId/:accountId',
  PRIVACY_POLICIES = 'policies/privacy',
  TERMS_POLICIES = 'policies/terms',
  REGISTER = 'register',
  COMPLETE = 'auth/complete/:accountId',
  CONFIRM = 'auth/confirm/:accountId',
}
